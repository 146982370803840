import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
  }

  span {
    color: var(--primary);
  }

  a {
    color: white;
  }

  p {
    line-height: 1.6em;
  }
`

const band_person = () => {
  return (
    <>
      <Seo title="Mateusz Łężniak" />
      <Layout>
        <SimpleBanner title="Mateusz Łężniak">
          <StaticImage
            className="banner__image"
            src="../../images/band/mateusz-b.jpg"
          />
        </SimpleBanner>
        <Container>
          <p>
            Absolwent Akademii Muzycznej w Krakowie na wydziale Jazzu w klasie
            saksofonu. Muzyk z wieloletnim doświadczeniem estradowym i
            eventowym. Występował na polskich jak i zagranicznych estradach.
            Wraz z różnymi formacjami wystąpił na takich imprezach jak:
          </p>
          <ul>
            <li>Woodstock Kostrzyn nad Odrą (obecnie Pol’and’Rock Festival)</li>
            <li>Festival Gwiazd w Miedzyzdrojach</li>
            <li>Ostróda Reggae Festival</li>
            <li>Płock Reggaeland</li>
            <li>Reggae Na Piaskach Ostrów Wielkopolski</li>
            <li>Big Band Festival Nowy Tomyśl</li>
            <li>Echo Trombity</li>
            <li>Festival „Lichen” Czechy</li>
          </ul>
        </Container>
      </Layout>
    </>
  )
}

export default band_person
